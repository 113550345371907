import React from "react";
import { Link, graphql } from "gatsby";
import useScript from "../hooks/useScript";
import { PopupButton } from "react-calendly";

import Layout from "../components/layout";

import IconCall from "../images/icons/theme/communication/call-1.svg";
import IconMail from "../images/icons/theme/communication/send.svg";

import LogoSamsung from "../images/logos/brand/grey-samsung.svg";
import LogoRedbull from "../images/logos/brand/grey-hubspot.svg";
import LogoHubspot from "../images/logos/brand/grey-red-bull.svg";
import LogoTmobile from "../images/logos/brand/grey-t-mobile.svg";
import IconCheck from "../images/icons/interface/check.svg";
//import { useMixpanelTracker } from "../hooks/useMixpanelTracker";
import { FreeStrategySessionJsonLd } from "../components/jsonld/FreeStrategySessionJsonLd";

const StrategySessionPage = ({ data, location: { pathname } }) => {
/*   useMixpanelTracker("19", (mixpanel, formData) => {
    mixpanel.alias(formData["email"]);
    mixpanel.identify(formData["email"]);

    mixpanel.track("Strategy session form submission", {
      Name: formData["fullname"],
      Email: formData["email"],
      "Company name": formData["field[1]"],
      "Product link": formData["field[2]"],
      "About project": formData["field[3]"],
      "Project budget": formData["field[15]"],
      "How did you hear about us": formData["field[20]"]
    });

    mixpanel.people.set({
      $name: formData["fullname"],
      $email: formData["email"],
      "Product link": formData["field[2]"],
      "About project": formData["field[3]"],
      "Project budget": formData["field[15]"],
      "How did you hear about us": formData["field[20]"]
    });

    mixpanel.set_group("company", [formData["field[1]"]]);
    mixpanel.get_group("company", formData["field[1]"]).set({
      $name: formData["field[1]"]
    });
  }); */

  return (
    <Layout
      hideHeader
      headerBgClass="bg-primary-alt"
      hideFooter
      footerBgClass="bg-white"
      meta={{ seo: data.datoCmsPage.seo, path: pathname }}
    >
      <FreeStrategySessionJsonLd />
      <div className="contact-us-page">
        <div className="contact-us-page__bg bg-primary-alt">
          {/*<div className="divider">
            <Divider3 style={{ transform: 'matrix(-1, 0, 0, 1, 0, 0)' }} />
          </div>*/}
        </div>

        <section className="bg-primary-alt">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-8 mx-auto">
                <div className="badge badge-danger mt-2 mb-2">
                  Booked Out
                </div>
                <h1 className="mb-3">Yeah... about that 😬</h1>
                <p className="lead mb-3">The strategy sessions are all booked out at the moment. There's good news though. You can book 1 hour of UX consulting instead.</p>
                <Link
                  to="/ux-consulting"
                  id="freeconsultation"
                  className="btn btn-lg btn-primary lead-cta"
                >
                  UX Consulting
                </Link>

                <p className="lead mt-7 mb-3">
                  Our UX Consultations can help you with a variety of challenges. Here are a few examples:
                </p>

                <p className="lead mb-3">
                  In this <strong>30-minute Strategy Session</strong> we’ll
                  identify issues and challenges of your current or future
                  product.
                </p>

                {/* <p className="lead mb-3">
                  We'll also discuss which{" "}
                  <strong>opportunities you might be missing.</strong>
                </p>

                <p className="lead mb-3">
                  At the end of the consultation we'll let you know about the{" "}
                  <strong>best UX strategies specific to your business</strong>{" "}
                  and product in order to:
                </p> */}

                <ul className="list-unstyled">
                  <li className="d-flex py-3">
                    <div className="icon-round icon-round-full icon-round-xs bg-primary mr-2">
                      <IconCheck className="injected-svg icon bg-white" />
                    </div>
                    <span className="lead mt-n1">
                      Improve your product usability
                    </span>
                  </li>

                  <li className="d-flex py-3">
                    <div className="icon-round icon-round-full icon-round-xs bg-primary mr-2">
                      <IconCheck className="injected-svg icon bg-white" />
                    </div>
                    <span className="lead mt-n1">
                      Engage users at a higher level and outperform your
                      competition
                    </span>
                  </li>

                  <li className="d-flex py-3">
                    <div className="icon-round icon-round-full icon-round-xs bg-primary mr-2">
                      <IconCheck className="injected-svg icon bg-white" />
                    </div>
                    <span className="lead mt-n1">
                      Plan a redesign or launch of a new product
                    </span>
                  </li>
                </ul>

                {/* <div className="d-none d-md-block">
                  <p className="lead pt-4">You can also apply via:</p>
                  <ul className="list-unstyled mt-3">
                    <li className="mb-5 d-flex align-items-center">
                      <IconMail className="icon bg-yellow icon-md" />
                      <div className="ml-3 h4 mb-0">
                        <a href="mailto:contact@adamfard.com">
                          contact@adamfard.com
                        </a>
                      </div>
                    </li>
                  </ul>
                </div> */}

                        {/* <a href="tel:+4915735985861">+49 157 3598 5861</a> */}
                        {/* <PopupButton
                          className="btn btn-lg btn-primary-alt mt-3"
                          color="#00a2ff"
                          text="Book a timeslot"
                          textColor="#ffffff"
                          url="https://calendly.com/adamfard/30min-ux-ws"
                          utm={{
                            utmCampaign: "Calendly Link Integration",
                            utmMedium: "Organic",
                            utmSource: "Edtech Design"
                          }}
                        /> */}
                
                {/*<li className="mb-5 d-flex align-items-center">
                    <IconCall className="icon bg-yellow icon-md flip-x" />
                    <div className="ml-3 h4 mb-0">
                      <a href="tel:+4915735985861">+49 157 3598 5861</a>
                    </div>
                  </li>*/}
              </div>

              {/* <div className="col-12 col-md-6">
                <div className="card card--common card-body card--no-hover">
                  <div id="activecampaign-form">
                    <div id="activecampaign-form__wrapper">
                      <div className=" _form_19"></div>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="container mt-4">
                <div className="row text-center mb-3">
                  <div className="col">
                    <small className="text-uppercase">
                      Trusted by companies like
                    </small>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <ul className="Companieslogo d-flex align-items-center justify-content-center list-unstyled">
                      <li data-aos="fade-up" data-aos-delay="100">
                        <LogoSamsung className="" />
                      </li>
                      <li data-aos="fade-up" data-aos-delay="150">
                        <LogoTmobile className="" />
                      </li>
                      <li data-aos="fade-up" data-aos-delay="200">
                        <LogoRedbull className="" />
                      </li>
                      <li data-aos="fade-up" data-aos-delay="250">
                        <LogoHubspot className="" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* {useScript("https://adamfard48700.activehosted.com/f/embed.php?id=19")} */}
    </Layout>
  );
};

export default StrategySessionPage;

export const query = graphql`
  query StrategySessionPage {
    datoCmsPage(slug: { eq: "free-strategy-session" }) {
      seo {
        title
        description
        twitterCard
        image {
          url
        }
      }
    }
  }
`;
